import type { NextPage } from 'next'
import { Typography, Stack } from '@mui/material'

const Custom404: NextPage = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Stack direction="column" justifyContent="center">
                <Typography variant="h1">404 - Page Not Found</Typography>
            </Stack>
        </div>
    )
}

export default Custom404
